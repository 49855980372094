import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import SEO from '../Seo'

import './home.css'
import "@fontsource/codystar"
import "@fontsource/poppins"



export default function Home() {
    return (
        <>
        <SEO title="Portfolio" />
        <div className="container">
            <div style={{minHeight:'100vh'}}>
            <div className="w-screen text-center pt-10 md:pt-36">
                <span className="headerText yellowBG md:px-10"> BETTY LEUNG </span><br />
                <span className="subHeaderText yellowBG md:px-10">FULL STACK SOFTWARE ENGINEER</span>
            </div>
            <div className="menu w-screen flex flex-col text-center justify-center md:flex-row md:pt-48">
                <AniLink cover bg="yellow" direction="down" to="/me" className="menuItem yellowBG no-underline mt-6 mx-4 xl:mr-32 md:px-10">Me</AniLink>
                <AniLink cover bg="yellow" direction="down" to="/work" className="menuItem yellowBG mt-6 mx-4 xl:mr-32 md:px-10">Work</AniLink>
                <AniLink cover bg="yellow" direction="down" to="/app/login" className="menuItem yellowBG mt-6 mx-4 xl:mr-32 md:px-10">CV</AniLink>
                <AniLink cover bg="yellow" direction="down" to="/notes" className="menuItem yellowBG mt-6 mx-4 xl:mr-32 md:px-10">Notes</AniLink>
                <AniLink cover bg="yellow" direction="down" to="/contact" className="menuItem yellowBG mt-6 mx-4 md:px-10">Mail</AniLink>
            </div>
                <img src="/me.gif" alt="head" className="backgroundImage" />
            </div>
            </div>
        </>
        )
            
}
